import { Switch } from 'react-router-dom'
import { Prodivders } from '../pages/providers'

import ProviderCreateOrUpdate from '../pages/providers/form'
import Route from './Route'
import SignIn from '../pages'
import ForgotPassword from '../pages/Auth/forgot-password'
import TokenPassword from '../pages/Auth/token-password'
import UserProfile from '../pages/user-profile'
import { Users } from '../pages/users'
import UsersCreateOrUpdate from '../pages/users/form'
import { Clients } from '../pages/clients'
import ClientsCreateOrUpdate from '../pages/clients/form'
import { RawMaterials } from '../pages/raw-material'
import RawMaterialCreateOrUpdade from '../pages/raw-material/form'
import ConfigurationForm from '../pages/configurations'
import { Demands } from '../pages/demand'
import DemandsCreateOrUpdate from '../pages/demand/form'
import { SeviceOrder } from '../pages/demand/service_order'
import { ServiceOrderList } from '../pages/demand/service_order/list'
import { DeliveredDemands } from '../pages/demand/delivered'
import { MonthCosts } from '../pages/costs'
import Dashboard from '../pages/dashboard/dashboard'
import { Revenues } from '../pages/revenues'
import { Employees } from '../pages/employees'
import EmployeesCreateOrUpdate from '../pages/employees/form'

export default function Routes() {
  return (
    <Switch>
      {/* Dashboard */}
      <Route exact path="/dashboard" component={Dashboard} />
      {/* Auth */}
      <Route exact path="/" component={SignIn} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/token-password" component={TokenPassword} />
      {/* User */}
      <Route exact path="/user-profile/:id" component={UserProfile} />
      {/* Raw Materials */}
      <Route exact path="/raw-materials" component={RawMaterials} />
      <Route exact path="/raw-materials/create" component={RawMaterialCreateOrUpdade} />
      <Route exact path="/raw-materials/edit/:id" component={RawMaterialCreateOrUpdade} />
      {/* Providers */}
      <Route exact path="/providers" component={Prodivders} />
      <Route exact path="/providers/create" component={ProviderCreateOrUpdate} />
      <Route exact path="/providers/edit/:id" component={ProviderCreateOrUpdate} />
      {/* Users */}
      <Route exact path="/users" component={Users} />
      <Route exact path="/users/create" component={UsersCreateOrUpdate} />
      <Route exact path="/users/edit/:id" component={UsersCreateOrUpdate} />
      {/* Clients */}
      <Route exact path="/clients" component={Clients} />
      <Route exact path="/clients/create" component={ClientsCreateOrUpdate} />
      <Route exact path="/clients/edit/:id" component={ClientsCreateOrUpdate} />
      {/* Employees */}
      <Route exact path="/employees" component={Employees} />
      <Route exact path="/employees/create" component={EmployeesCreateOrUpdate} />
      <Route exact path="/employees/edit/:id" component={EmployeesCreateOrUpdate} />
      {/**Costs */}
      <Route exact path="/costs" component={MonthCosts} />
      <Route exact path="/revenues" component={Revenues} />
      {/* Configurations  */}
      <Route exact path="/configurations" component={ConfigurationForm} />
      {/* Demands  */}
      <Route exact path="/demands" component={Demands} />
      <Route exact path="/demands/delivered" component={DeliveredDemands} />
      <Route exact path="/demands/create" component={DemandsCreateOrUpdate} />
      <Route exact path="/demands/edit/:id" component={DemandsCreateOrUpdate} />
      <Route exact path="/demands/:id/services_order" component={SeviceOrder} />
      <Route exact path="/demands/:demandId/services_order/:id" component={ServiceOrderList} />
    </Switch>
  )
}
