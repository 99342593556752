import { Box, Button, Divider, Flex, Heading, HStack, SimpleGrid, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Input } from '../../../components/Form/Input'
import { Select } from '../../../components/Form/Select'
import { IEmployees } from '../../../Interfaces/employees'
import { api } from '../../../services/api'
import { states } from '../../../utils/states'

const createEmployeeSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  phone: yup.string().required('Telefone obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
})

export default function EmployeesCreateOrUpdate({ match }: any) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { id } = match.params

  const isCreateMode = !id

  const createEmployee = useMutation(
    async (employee: IEmployees) => {
      const responseAddress = await api.post('addresses', {
        zip_code: employee.address.zip_code,
        state: employee.address.state,
        city: employee.address.city,
        neighborhood: employee.address.neighborhood,
        complement: employee.address.complement,
      })
      if (responseAddress) {
        employee.address_id = responseAddress.data.id
        const response = await api.post('employees', {
          address_id: employee.address_id,
          name: employee.name,
          cpf: employee.cpf,
          email: employee.email,
          phone: employee.phone,
          hourly_value: employee.hourly_value,
        })
        if (response) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employees')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateEmployee = useMutation(
    async (employee: IEmployees) => {
      const responseAddress = await api.put(`addresses/${employee.address_id}`, {
        zip_code: employee.address.zip_code,
        state: employee.address.state,
        city: employee.address.city,
        neighborhood: employee.address.neighborhood,
        complement: employee.address.complement,
      })
      if (responseAddress) {
        employee.address_id = responseAddress.data.id
        const responseEmployee = await api.put(`employees/${id}`, {
          address_id: employee.address_id,
          name: employee.name,
          cpf: employee.cpf,
          email: employee.email,
          phone: employee.phone,
          hourly_value: employee.hourly_value,
        })
        if (responseEmployee) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employees')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createEmployeeSchema),
  })

  useQuery('employees', async () => {
    const { data } = await api.get(`employees/${id}`)
    reset(data)
  })

  const errors = formState.errors

  const handleCreateEmployees: SubmitHandler<IEmployees> = async (values) => {
    isCreateMode ? await createEmployee.mutateAsync(values) : await updateEmployee.mutateAsync(values)

    history.push('/employees')
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateEmployees)}>
      <Heading size="lg" fontWeight="normal">
        {isCreateMode ? 'Novo Funcionário' : 'Editar Funcionário'}
      </Heading>
      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="14" width="100%">
          <Input label="Nome" {...register('name')} error={errors.name as FieldError} />
          <Input
            label="CPF"
            {...register('cpf')}
            error={errors.cnpj as FieldError}
            as={InputMask}
            mask="999.999.999-99"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Telefone" {...register('phone')} error={errors.phone as FieldError} />
          <Input label="E-mail" {...register('email')} error={errors.email as FieldError} />
          <Input
            label="Valor por Hora"
            type="number"
            step="0.01"
            {...register('hourly_value')}
            error={errors.hourly_value as FieldError}
          />
        </SimpleGrid>
        <Heading size="md" fontWeight="normal">
          Endereço
        </Heading>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="CEP"
            {...register('address.zip_code')}
            error={(errors.address as { zip_code?: FieldError })?.zip_code || undefined}
            as={InputMask}
            mask="99999-999"
          />
          <Input
            label="Cidade"
            {...register('address.city')}
            error={(errors.address as { city?: FieldError })?.city || undefined}
          />
          <Select label="Estado" {...register('address.state')} options={states} placeholder="Estado" />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Endereço"
            {...register('address.complement')}
            error={(errors.address as { complement?: FieldError })?.complement || undefined}
          />
          <Input
            label="Bairro"
            {...register('address.neighborhood')}
            error={(errors.address as { neighborhood?: FieldError })?.neighborhood || undefined}
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to={`/employees`} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
