import { Box, Button, Flex, Heading, Tag } from '@chakra-ui/react'

interface Props {
  title: string
  handleAction: () => void
}

export function Header({ title, handleAction }: Props) {
  return (
    <Flex justifyContent="space-between" align="center">
      <Box>
        <Heading size="lg" fontWeight="normal">
          <Tag size="lg" variant="solid" colorScheme="teal">
            {title}
          </Tag>
        </Heading>
      </Box>
      <Button size="sm" fontSize="sm" colorScheme="blue" onClick={handleAction}>
        Novo
      </Button>
    </Flex>
  )
}
