import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal as ChacraModal,
} from '@chakra-ui/react'
import React from 'react'

type ModalProps = {
  isOpen: boolean
  title: string
  onClose: () => void
  children?: React.ReactNode
  buttonAction: () => void
  buttonTitle: string
  size?: string
  hasFooter?: boolean
}

export function Modal({
  isOpen,
  onClose,
  title,
  buttonTitle,
  buttonAction,
  size = 'sm',
  hasFooter = true,
  children,
}: ModalProps) {
  return (
    <ChacraModal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent bgColor="gray.800">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {hasFooter && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button variant="unstyled" onClick={buttonAction}>
              {buttonTitle}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChacraModal>
  )
}
