import { createContext, ReactNode, useEffect, useState } from 'react'
import { conditionalRegex, defaultRegex, normalize } from '../utils/searchRequirements'

type Props = {
  children: ReactNode
}

type SearchContextValues = {
  path?: string
  searchValue?: string
  setPath?: React.Dispatch<React.SetStateAction<string>>
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>
  queryContextParams?: { [key: string]: any }
  clearQuery: () => void
  isShortScreenOpen: boolean
  setIsShortScreenOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SearchContext = createContext({} as SearchContextValues)

export const SearchProvider = ({ children }: Props) => {
  const [path, setPath] = useState<string>()

  const [searchValue, setSearchValue] = useState<string>()

  const [isShortScreenOpen, setIsShortScreenOpen] = useState(false)

  const [queryContextParams, setQueryContextParams] = useState<{ [key: string]: any }>()

  useEffect(() => {
    makeQuery(searchValue)
  }, [path, searchValue])

  const makeQuery = (txtToSearch) => {
    if (txtToSearch && path && conditionalRegex[path]) {
      const query = matchRegex(txtToSearch, conditionalRegex[path])

      let params =
        !!query && typeof query === 'object' && Object.keys(query).length > 0
          ? query
          : matchRegex(txtToSearch, defaultRegex[path])

      Object.keys(params).forEach((key) => (params[key] = params[key].trim()))

      if (normalize[path]) params = normalize[path](params)

      setQueryContextParams(params)
    } else setQueryContextParams({})
  }

  const matchRegex = (txt: string, patterns: RegExp[]) => {
    const foundItens = {}

    patterns.forEach((rx) => {
      const target = txt.match(rx)

      if (target && target.groups) Object.assign(foundItens, target.groups)
    })

    return foundItens
  }

  const clearQuery = () => {
    setQueryContextParams({})
  }

  return (
    <SearchContext.Provider
      value={{
        path,
        setPath,
        searchValue,
        setSearchValue,
        queryContextParams,
        clearQuery,
        isShortScreenOpen,
        setIsShortScreenOpen,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
