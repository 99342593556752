import { Flex, Input, Icon, Box, useBreakpointValue, HStack } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowLeftLine, RiCloseFill, RiSearchLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { SearchContext } from '../../context/SearchContext'

type Props = {
  isIconVersion?: boolean
}

export function Search({ isIconVersion }: Props) {
  const history = useHistory()

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const { register, handleSubmit, setValue } = useForm({})

  const { setPath, setSearchValue, isShortScreenOpen, setIsShortScreenOpen } = useContext(SearchContext)

  useEffect(() => {
    if (isWideVersion && isShortScreenOpen) setIsShortScreenOpen(false)
  }, [isWideVersion])

  const searchSubmit = (data) => {
    setPath(history.location.pathname)

    setSearchValue(data.search)
  }

  const closeSearch = () => {
    setIsShortScreenOpen(false)
  }

  const clearSearch = () => {
    setValue('search', '')
    setSearchValue('')
  }

  return (
    <>
      {!isIconVersion ? (
        <Box as="form" flexDir="column" onSubmit={handleSubmit(searchSubmit)}>
          <Flex
            as="label"
            flex="1"
            py="4"
            px="8"
            ml="6"
            maxWidth={400}
            alignSelf="center"
            color="gray.200"
            position="relative"
            bg="gray.800"
            borderRadius="full"
          >
            <Input
              color="gray.50"
              variant="unstyled"
              px="4"
              mr="4"
              placeholder="Buscar na plataforma"
              _placeholder={{ color: 'gray.400' }}
              {...register('search')}
            />
            <Flex alignItems="center">
              <HStack spacing="1rem">
                <Icon as={RiCloseFill} fontSize="25" onClick={clearSearch} cursor="pointer" />

                <Icon as={RiSearchLine} fontSize="20" onClick={handleSubmit(searchSubmit)} cursor="pointer" />
              </HStack>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <>
          {!isWideVersion && isShortScreenOpen && (
            <Box as="form" w="100%" display="contents" onSubmit={handleSubmit(searchSubmit)}>
              <Flex alignItems="center">
                <Icon
                  as={RiArrowLeftLine}
                  fontSize="25"
                  onClick={closeSearch}
                  cursor="pointer"
                  _hover={{ color: 'pink.A400' }}
                />
              </Flex>
              <Flex
                as="label"
                flex="1"
                py="4"
                px="8"
                ml="6"
                alignSelf="center"
                color="gray.200"
                position="relative"
                bg="gray.800"
                borderRadius="full"
              >
                <Input
                  color="gray.50"
                  variant="unstyled"
                  px="4"
                  mr="4"
                  placeholder="Buscar na plataforma"
                  _placeholder={{ color: 'gray.400' }}
                  {...register('search')}
                />

                <Flex alignItems="center">
                  <HStack spacing="1rem">
                    <Icon
                      as={RiCloseFill}
                      fontSize="25"
                      onClick={clearSearch}
                      cursor="pointer"
                      _hover={{ color: 'pink.A400' }}
                    />

                    <Icon
                      as={RiSearchLine}
                      fontSize="20"
                      onClick={handleSubmit(searchSubmit)}
                      cursor="pointer"
                      _hover={{ color: 'pink.A400' }}
                    />
                  </HStack>
                </Flex>
              </Flex>
            </Box>
          )}
        </>
      )}
    </>
  )
}
