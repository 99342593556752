import { Box } from '@chakra-ui/react'
import { Step } from '../../Enuns/step.enum'
import { RawMaterial } from '../../Interfaces/rawMaterial'
import { WorkSchedule } from '../../Interfaces/workSchedule'
import { Header } from './header'
import { List } from './list'
import { RawList } from './rawList'

interface Props {
  title: string
  workSchedules: WorkSchedule[]
  rawMaterials: RawMaterial[]
  handleWorkSchedule: (step: any, workSchedule?: any) => void
  handleRawMaterial: (step: any, workSchedule?: any) => void
  step: Step
}

export function CoastRawMaterial({
  title,
  workSchedules,
  rawMaterials,
  handleWorkSchedule,
  handleRawMaterial,
  step,
}: Props) {
  return (
    <Box mb="9" display="grid" gridGap="10" gridTemplateColumns="2fr 2fr" bg="gray.700" p="5" borderRadius="2xl">
      <div>
        <Box flex="1" bg="gray.600" p="2" borderRadius="8" as="form">
          <Header title={title} handleAction={handleWorkSchedule} step={step} />
        </Box>
        <List workSchedules={workSchedules} handleAction={handleWorkSchedule} step={step} />
      </div>
      <div>
        <Box flex="1" bg="gray.600" p="2" borderRadius="8" as="form">
          <Header title="Matéria Prima" handleAction={handleRawMaterial} step={step} />
        </Box>
        <RawList rawMaterials={rawMaterials} handleAction={handleRawMaterial} step={step} />
      </div>
    </Box>
  )
}
