import { useQuery } from 'react-query'
import { User } from '../../Interfaces/users'
import { api } from '../api'

type GetUsersResponse = {
  totalCount: number
  users: User[]
}

type QueryRawMaterialType = {
  page?: number
  name?: string
  username?: string
  email?: string
}

export async function getUsers(params: QueryRawMaterialType): Promise<GetUsersResponse> {
  const { data } = await api.get('users', {
    params,
  })

  return {
    users: data.data,
    totalCount: data.meta.total,
  }
}

export function useUsers(params: QueryRawMaterialType) {
  return useQuery(['users', params], () => getUsers(params), {
    staleTime: 5000,
  })
}
