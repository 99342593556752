import {
  Box,
  Button,
  Checkbox,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Tag,
  IconButton,
  Tooltip,
  VStack,
  HStack,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiEye2Line, RiPencilLine } from 'react-icons/ri'
import { useMutation } from 'react-query'
import { Modal } from '../../../components/Modal'
import { Pagination } from '../../../components/Pagination'
import { Demand } from '../../../Interfaces/demands'
import { api } from '../../../services/api'
import { useDemands } from '../../../services/hooks/useDemands'
import { SearchContext } from '../../../context/SearchContext'
import DemandsCreateOrUpdate from '../form/index'
import { format, parseISO } from 'date-fns'
import { DemandStatus } from '../../../Enuns/demandStatus.enum'
import { FiAlertTriangle } from 'react-icons/fi'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import { Input } from '../../../components/Form/Input'
import InputMask from 'react-input-mask'
import { SubmitHandler, useForm } from 'react-hook-form'

export function DeliveredDemands() {
  const toast = useToast()

  const [page, setPage] = useState(1)

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const [periodStartDate, setPeriodStartDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'dd/MM/yyyy'),
  )

  const [periodEndDate, setPeriodEndDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'dd/MM/yyyy'),
  )

  const { data, isLoading, isFetching, error, refetch } = useDemands(
    {
      page,
      ...queryContextParams,
      status: DemandStatus.ENTREGUE,
    },
    periodStartDate,
    periodEndDate,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentDemand, setCurrentDemand] = useState<Demand>()

  useEffect(() => {
    refetch()
  }, [queryContextParams])

  useEffect(() => {
    return () => {
      clearQuery()

      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentDemand) removeDemand.mutateAsync(currentDemand)

    onClose()
  }

  const removeDemand = useMutation(
    async (demand: Demand) => {
      const response = await api.delete(`demands/${demand.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
      }

      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao remover pedido', status: 'error' })
        }
      },
    },
  )

  const handleModalOpen = async (demand = null) => {
    const { data } = await api.get(`/demands/${demand.id}`)

    setCurrentDemand(data)
    onOpen()
  }

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: 'onSubmit',
  })

  const errors = formState.errors

  const updatePayday = useMutation(
    async ({ payday, order }: any) => {
      const date = payday.split('/')

      const response = await api.put(`/demands/${currentDemand.id}/service-orders/${order}/payment`, {
        payday: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
      })

      if (response) {
        toast({ description: 'Pagamento informado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries('demands');
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleCreate: SubmitHandler<any> = async (values) => {
    await updatePayday.mutateAsync(values)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Pedidos Entregues
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter os pedidos entregues</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>Pedido</Th>
              <Th>Status</Th>
              <Th>Data da entrega</Th>
              <Th width="4" />
            </Tr>
          </Thead>
          <Tbody>
            {data?.demands.map((demand: Demand) => {
              return (
                <Tr key={demand.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{demand.client.company_name}</Text>
                      <Text fontSize="small" color="gray.300">
                        {demand.client.resp_name}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    <Tag size="md" variant="solid" colorScheme="blue">
                      ENTREGUE
                    </Tag>
                  </Td>
                  <Td>{format(parseISO(demand.updated_at), 'dd/MM/yyyy')}</Td>
                  <Td display="grid" gridGap="2" gridTemplateColumns="1fr 1fr 1fr">
                    <Tooltip label="Vislizar Ordens" placement="top">
                      <IconButton
                        onClick={() => handleModalOpen(demand)}
                        size="md"
                        fontSize="sm"
                        colorScheme="purple"
                        aria-label="Vislizar Ordens"
                        icon={<Icon as={RiEye2Line} />}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Pagination totalCountOfRegisters={data?.totalCount} currentPage={page} onPageChange={setPage} />

      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        title="Vislizar Ordens"
        hasFooter={false}
        buttonTitle=""
        buttonAction={() => {}}
      >
        <Text mb="5" fontWeight="bold">
          {`Cliente: ${currentDemand?.client?.company_name}`}
        </Text>

        {currentDemand?.serviceOrders.map((order) => {
          return (
            <>
              <Heading size="md" fontWeight="normal" mt={3} backgroundColor="gray.400" p={1}>
                Ordem - {order.id} - <Tag>{order.payday ? 'Pago' : 'À pagar'}</Tag>
              </Heading>
              <List spacing={3} mt="3" pl={5} mb={10}>
                {order.products.map((product) => {
                  return (
                    <>
                      <ListItem>
                        <ListIcon as={RiCheckLine} color="green.500" />
                        {`${product.description} - `}
                        R${' '}
                        <CurrencyFormat
                          displayType="text"
                          decimalSeparator="."
                          thousandSeparator=","
                          value={product.price * product.amount}
                        />
                      </ListItem>
                    </>
                  )
                })}
              </List>
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="blue"
                as={Link}
                to={`/demands/${currentDemand.id}/services_order/${order.id}?delivered=true`}
              >
                Detalhar
              </Button>
            </>
          )
        })}
      </Modal>
    </Box>
  )
}
