import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  useToast,
  VStack,
} from '@chakra-ui/react'
import EditPassword from './password-edit'
import EditUser from './user-edit'

export default function UserProfile({ match }: any) {
  const { id } = match.params

  const toast = useToast()

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Heading size="lg" fontWeight="normal">
        Seu Perfil
      </Heading>

      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="4" align="stretch">
        <Accordion pt="4" allowMultiple defaultIndex={[0]}>
          <AccordionItem borderBottom="none">
            <AccordionButton _focus={{ boxShadow: 'none' }}>
              <Box flex="1" textAlign="left">
                Editar Usuario
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p="6">
              <EditUser id={id} />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem borderBottom="none">
            <AccordionButton _focus={{ boxShadow: 'none' }}>
              <Box flex="1" textAlign="left">
                Alterar sua senha
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p="6">
              <EditPassword />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </Box>
  )
}
