import { Link, Icon, Text, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { ElementType } from 'react'
import { ActiveLink } from '../ActiveLink'

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType
  path?: string
  children: string
}

export function NavLink({ icon, children, path = '/', ...rest }: NavLinkProps) {
  return (
    <ActiveLink to={path}>
      <Link display="flex" align="center" {...rest}>
        <Icon as={icon} fontSize="20" />

        <Text ml="4" fontWeight="medium">
          {children}
        </Text>
      </Link>
    </ActiveLink>
  )
}
