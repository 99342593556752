import { useQuery } from 'react-query'
import { IEmployees } from '../../Interfaces/employees'
import { api } from '../api'

type GetEmployeesResponse<T> = {
  totalCount?: number
  employees: T
}

type QueryEmployeeType = {
  page?: number
  isSelect?: boolean
  name?: string
  cpf?: string
  email?: string
  staleTime?: boolean
}

export async function getEmployees<T>(params: QueryEmployeeType): Promise<GetEmployeesResponse<T>> {
  const { isSelect } = params

  let { data } = await api.get('employees', {
    params,
  })

  if (isSelect) {
    data = data.map((x: IEmployees) => ({
      label: x.name,
      value: x.id,
    }))

    return {
      employees: data,
    }
  }

  return {
    employees: data.data,
    totalCount: data.meta.total,
  }
}

export function useEmployees<T>(params: QueryEmployeeType) {
  let options = {}

  if (params.staleTime) {
    options = { staleTime: 5000 }
  } else {
    options = {
      retry: false,
    }
  }

  return useQuery<GetEmployeesResponse<T>>(['employees', params], () => getEmployees<T>(params), options)
}
