import { Table, Tbody, Td, Tr, Th, Thead, Tooltip, IconButton, Icon } from '@chakra-ui/react'
import { RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { Step } from '../../Enuns/step.enum'
import { RawMaterial } from '../../Interfaces/rawMaterial'

interface Props {
  rawMaterials: RawMaterial[]
  handleAction: (step: Step, workSchedule: any) => void
  step: Step
}

export function RawList({ rawMaterials, handleAction, step }: Props) {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Matéria</Th>
            <Th>Quantidade</Th>
            <Th>Custo</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {rawMaterials?.map((product) => {
            return (
              <Tr key={product.id}>
                <Td>{product[0].name}</Td>
                <Td>{product[0].spent_amount}</Td>
                <Td>{(parseFloat(product[0].spent_amount) * parseFloat(String(product[0].price))).toFixed(2)}</Td>

                <Td display="grid" gridGap="2" gridTemplateColumns="1fr 1fr 1fr">
                  <Tooltip label="Editar" placement="top">
                    <IconButton
                      onClick={() => handleAction(step, product)}
                      size="md"
                      fontSize="sm"
                      colorScheme="purple"
                      aria-label="Editar"
                      icon={<Icon as={RiPencilLine} />}
                    />
                  </Tooltip>
                  <Tooltip label="Remover" placement="top">
                    <IconButton
                      onClick={() => handleAction(step, { ...product, deleted: true })}
                      size="md"
                      fontSize="sm"
                      colorScheme="red"
                      aria-label="Remover"
                      icon={<Icon as={RiDeleteBin6Line} />}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}
