import { Box } from '@chakra-ui/react'
import { Product } from '../../Interfaces/products'
import { Header } from './header'
import { List } from './list'

interface Props {
  title: string
  products: Product[]
  handleAction: () => void
}

export function ServiceOrderGradeList({ title, products, handleAction }: Props) {
  return (
    <Box mb="9">
      <Box flex="1" bg="gray.600" p="2" borderRadius="8" as="form">
        <Header title={title} handleAction={handleAction} />
      </Box>
      <List products={products} handleAction={handleAction} />
    </Box>
  )
}
