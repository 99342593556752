import { Table, Tbody, Td, Tr, Th, Thead } from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'
import { Product } from '../../Interfaces/products'

export function List({ serviceOrder }: any) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Nome</Th>
          <Th>Quantidade</Th>
          <Th>Cor</Th>
          <Th>Queima</Th>
          <Th>Entrega</Th>
        </Tr>
      </Thead>
      <Tbody>
        {serviceOrder?.products.map((product: Product) => {
          return (
            <Tr key={product.id}>
              <Td>{product.description}</Td>
              <Td>{product.amount}</Td>
              <Td>{product.ink_color.name}</Td>
              <Td>{product.oven_space}</Td>
              <Td>{format(parseISO(product.delivery_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
