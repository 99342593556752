import {
  Box,
  Button,
  Checkbox,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { useMutation } from 'react-query'
import { Modal } from '../../components/Modal'
import { Pagination } from '../../components/Pagination'
import { User } from '../../Interfaces/users'
import { api } from '../../services/api'
import { useUsers } from '../../services/hooks/useUsers'
import { Link } from 'react-router-dom'
import { SearchContext } from '../../context/SearchContext'

export function Users() {
  const toast = useToast()

  const [page, setPage] = useState(1)

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const { data, isLoading, isFetching, error, refetch } = useUsers({ page, ...queryContextParams })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentUser, setCurrentUser] = useState<User>()

  useEffect(() => {
    refetch()
  }, [queryContextParams])

  useEffect(() => {
    return () => {
      clearQuery()

      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentUser) removeUser.mutateAsync(currentUser)

    onClose()
  }

  const removeUser = useMutation(
    async (user: User) => {
      const response = await api.delete(`users/${user.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
      }

      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleModalOpen = (user) => {
    setCurrentUser(user)

    onOpen()
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Usuários
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>

        <Button
          as={Link}
          to="/users/create"
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}
        >
          Criar Novo
        </Button>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter os fornecedores</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>Nome</Th>
              <Th>Perfil</Th>
              <Th width="4" />
              <Th width="4" />
            </Tr>
          </Thead>
          <Tbody>
            {data?.users.map((user: User) => {
              return (
                <Tr key={user.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{user.name}</Text>
                      <Text fontSize="small" color="gray.300">
                        {user.email}
                      </Text>
                    </Box>
                  </Td>

                  <Td>{user.access}</Td>
                  <Td>
                    <Button
                      as={Link}
                      to={`/users/edit/${user.id}`}
                      size="sm"
                      fontSize="sm"
                      colorScheme="purple"
                      leftIcon={<Icon as={RiPencilLine} />}
                    >
                      Editar
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen(user)}
                      size="sm"
                      fontSize="sm"
                      colorScheme="red"
                      leftIcon={<Icon as={RiDeleteBin6Line} />}
                    >
                      Remover
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Pagination totalCountOfRegisters={data?.totalCount} currentPage={page} onPageChange={setPage} />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Remover Fornecedor"
        buttonTitle="Remover"
        buttonAction={handleRemoveButton}
      >
        <List spacing={3}>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Nome: ${currentUser?.name}`}
          </ListItem>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`E-mail: ${currentUser?.email}`}
          </ListItem>
        </List>
      </Modal>
    </Box>
  )
}
