/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/layout'
import { Button, Icon, useDisclosure } from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'
import { useQuery } from 'react-query'
import { GradeListing } from '../../../components/GradeListing'
import { Modal } from '../../../components/Modal'
import { Demand } from '../../../Interfaces/demands'
import { api } from '../../../services/api'
import { useServiceOrders } from '../../../services/hooks/useServiceOrders'
import ServiceCreateOrUpdate from './form'

export function SeviceOrder({ match }: any) {
  const id = match.params.id

  const { data: demands } = useQuery(
    'demand',
    async () => {
      const response = await api.get<Demand>(`demands/${id}`)

      return response.data
    },
    {
      retry: false,
    },
  )

  const { data } = useServiceOrders({
    demandId: id,
  })

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()

  const handleAddServiceOrder = () => {
    onCreateOpen()
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Box>
          <Heading size="lg" fontWeight="normal">
            Pedido Nº {id}
          </Heading>

          <Text fontSize="1xl"> {demands?.client.company_name}</Text>
        </Box>
        <Button
          onClick={handleAddServiceOrder}
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}
        >
          Nova OS
        </Button>
      </Flex>
      <Divider my="6" borderColor="gray.700" />

      <GradeListing data={data?.serviceOrders} />

      <Modal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        title="Nova ordem de serviço"
        buttonTitle="Adicionar"
        size="xl"
        hasFooter={false}
        buttonAction={() => {}}
      >
        <ServiceCreateOrUpdate currendDemand={demands} onClose={onCreateClose} />
      </Modal>
    </Box>
  )
}
