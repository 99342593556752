import { useQuery } from 'react-query'
import { ServiceOrders } from '../../Interfaces/serviceOrders'
import { api } from '../api'

type GetServiceOrdersResponse = {
  serviceOrders: ServiceOrders[]
}

type QueryServiceOrdersType = {
  page?: number
  demandId?: number
}

export async function getServieOrders(params: QueryServiceOrdersType): Promise<GetServiceOrdersResponse> {
  const { data } = await api.get(`/demands/${params.demandId}/service-orders`)

  return {
    serviceOrders: data,
  }
}

export function useServiceOrders(params: QueryServiceOrdersType) {
  return useQuery(['serviceOrders', params], () => getServieOrders(params), {
    staleTime: 5000,
  })
}
