import { Stack } from '@chakra-ui/react'
import {
  RiInkBottleLine,
  RiBuilding4Line,
  RiPriceTag3Fill,
  RiShieldUserLine,
  RiTeamLine,
  RiTruckFill,
  RiDashboard2Fill,
} from 'react-icons/ri'
import { MdAttachMoney } from 'react-icons/md'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">
        <NavLink icon={RiDashboard2Fill} path="/dashboard">
          Dashboard
        </NavLink>

        <NavLink icon={RiBuilding4Line} path="/providers">
          Fornecedores
        </NavLink>

        <NavLink icon={RiInkBottleLine} path="/raw-materials">
          Matéria prima
        </NavLink>

        <NavLink icon={RiShieldUserLine} path="/users">
          Usuários
        </NavLink>

        <NavLink icon={RiTeamLine} path="/employees">
          Funcionários
        </NavLink>

        <NavLink icon={RiTeamLine} path="/clients">
          Clientes
        </NavLink>

        <NavLink icon={MdAttachMoney} path="/demands">
          Pedidos
        </NavLink>

        <NavLink icon={RiTruckFill} path="/demands/delivered">
          Entregues
        </NavLink>
        <NavLink icon={RiPriceTag3Fill} path="/costs">
          Despesas
        </NavLink>
        <NavLink icon={RiPriceTag3Fill} path="/revenues">
          Receitas
        </NavLink>
      </NavSection>
    </Stack>
  )
}
