import { Box, Button, Checkbox, CheckboxGroup, Flex, HStack, SimpleGrid, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'
import { Input } from '../../../../../components/Form/Input'
import { Step } from '../../../../../Enuns/step.enum'
import { WorkSchedule } from '../../../../../Interfaces/workSchedule'
import { WorkScheduleEmployee } from '../../../../../Interfaces/workScheduleEmployee'
import { api } from '../../../../../services/api'

// Validação do esquema com Yup
const createServiceOrderSchema = yup.object().shape({
  work_date: yup.string().required('Informe a data'),
  start_work: yup.string().required('Informe a hora de início'),
  end_work: yup.string().required('Informe a hora de término'),
  employee_ids: yup.array().of(yup.number()).min(1, 'Selecione pelo menos um funcionário'),
})

interface Props {
  selectedProduct?: WorkSchedule
  orderId: string
  onClose: () => void
  step: Step
}

export default function WorkScheduleCreateOrUpdate({ selectedProduct, onClose, orderId, step }: Props) {
  const queryClient = useQueryClient()
  const toast = useToast()

  const id = selectedProduct?.id ?? ''

  const { register, handleSubmit, formState, reset, getValues } = useForm({
    resolver: yupResolver(createServiceOrderSchema),
  })

  const errors = formState.errors
  const [selectedEmployees, setSelectedEmployees] = useState<WorkScheduleEmployee[]>([])
  const [hourlyValue, setHourlyValue] = useState(0)
  const [totalValue, setTotalValue] = useState(0)

  // Requisição para buscar os funcionários
  const { data: employees, isLoading: isEmployeesLoading } = useQuery(
    'employees',
    async () => {
      const response = await api.get('/employees')
      return response.data
    },
    {
      retry: false,
    },
  )

  const isCreateMode = !id

  const createDemand = useMutation(
    async (product: WorkSchedule) => {
      const date = product.work_date.split('/')

      const workSchedule = {
        ...product,
        work_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        step,
        employees: selectedEmployees, // Apenas IDs
        hourly_value: hourlyValue, // Envia o valor total calculado
      }

      const response = await api.post(`/service-orders/${orderId}/work-schedules`, workSchedule)
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workSchedule')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => toast(t))
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  useEffect(() => {
    if (id && selectedProduct) {
      reset({
        ...selectedProduct,
        work_date: selectedProduct.work_date ? format(new Date(selectedProduct.work_date), 'dd/MM/yyyy') : '',
      })
      setSelectedEmployees(selectedProduct.employees.map((employee) => employee))
      if (selectedProduct) {
        calculateTotalValue(selectedProduct)
      }
    }
  }, [id, selectedProduct?.employees, reset])

  const updateDemands = useMutation(
    async (product: WorkSchedule) => {
      const date = product.work_date.split('/')

      const workSchedule = {
        ...product,
        work_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        step,
        employees: selectedEmployees, // Apenas IDs
        hourly_value: hourlyValue,
        total_value: totalValue, // Envia o valor total calculado
      }

      const response = await api.put(`/service-orders/${orderId}/work-schedules/${id}`, workSchedule)
      if (response) {
        toast({ description: 'Atualizado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workSchedule')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => toast(t))
        } else {
          toast({ description: 'Falha ao atualizar', status: 'error' })
        }
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<WorkSchedule> = async (values) => {
    const formattedData = {
      ...values,
      employees: selectedEmployees,
      work_date: format(new Date(values.work_date.split('/').reverse().join('-')), 'yyyy-MM-dd'),
    }

    isCreateMode ? await createDemand.mutateAsync(formattedData) : await updateDemands.mutateAsync(formattedData)
  }

  const calculateTotalValue = (selectedProduct) => {
    const startTime = selectedProduct?.start_work ? selectedProduct.start_work : getValues('start_work')
    const endTime = selectedProduct?.end_work ? selectedProduct.end_work : getValues('end_work')

    const employees = selectedProduct.employees
    if (!employees) return
    let totalHourlyValue

    if (!selectedProduct.total_value || selectedProduct.total_value === '0.00') {
      const totalHourlyValue = calculateTotalHourlyValue(employees)
      const hoursWorked = calculateHoursWorked(startTime, endTime)

      setTotalValue(hoursWorked * totalHourlyValue)
      setHourlyValue(totalHourlyValue)

      reset({
        ...getValues(),
        hourly_value: totalHourlyValue,
        total_value: (hoursWorked * totalHourlyValue).toFixed(2),
      })
    } else {
      const totalHourlyValue = calculateTotalHourlyValue(employees)
      calculateHoursWorked(startTime, endTime)

      setHourlyValue(totalHourlyValue)
    }
  }

  const calculateTotalHourlyValue = (employees) => {
    return employees
      .map((employee) => (employee ? Number(employee.hourly_value) : 0))
      .reduce((sum, value) => sum + value, 0)
  }

  const calculateHoursWorked = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number)
    const [endHours, endMinutes] = endTime.split(':').map(Number)

    const start = new Date()
    start.setHours(startHours, startMinutes, 0)

    const end = new Date()
    end.setHours(endHours, endMinutes, 0)

    return Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60))
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Data"
            {...register('work_date')}
            error={errors.work_date as FieldError}
            as={InputMask}
            mask="99/99/9999"
          />
          <Input
            label="Hr. Início"
            {...register('start_work')}
            error={errors.start_work as FieldError}
            as={InputMask}
            mask="99:99:00"
            onChange={() => calculateTotalValue(selectedProduct)}
          />
          <Input
            label="Hr. Final"
            {...register('end_work')}
            error={errors.end_work as FieldError}
            as={InputMask}
            mask="99:99:00"
            onChange={() => calculateTotalValue(selectedProduct)}
          />

          <Input
            label="Valor Hora Total"
            {...register('hourly_value')}
            name="hourly_value"
            isReadOnly
            error={errors.hourly_value as FieldError}
          />

          <Input
            label="Valor Total"
            name="total_value"
            {...register('total_value')}
            onChange={(e) => setTotalValue(Number(e.target.value))}
            error={errors.total_value as FieldError}
          />

          <CheckboxGroup
            value={selectedEmployees.map((employee) => employee.id.toString())}
            onChange={(values) => {
              const updatedEmployees = values
                .map((value) => {
                  const employee = employees?.find((emp) => emp.id === Number(value))
                  return employee ? employee : null
                })
                .filter(Boolean) as WorkScheduleEmployee[]

              setSelectedEmployees(updatedEmployees)
              calculateTotalValue({ ...selectedProduct, employees: updatedEmployees })
            }}>
            {employees?.map((employee) => (
              <Checkbox key={employee.id} value={employee.id.toString()}>
                {employee.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
