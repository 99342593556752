import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ChakraProvider } from '@chakra-ui/react'
import { ReactQueryDevtools } from 'react-query/devtools'
import Routes from './routes'
import { theme } from './styles/theme'
import { SidebarDrawerProvider } from './context/SidebarDrawer'
import { AuthProvider } from './context/AuthContext'
import { SearchProvider } from './context/SearchContext'
import { ConfigurationProvider } from './context/ConfigurationContext'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <ConfigurationProvider>
            <SidebarDrawerProvider>
              <BrowserRouter>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
              </BrowserRouter>
            </SidebarDrawerProvider>
          </ConfigurationProvider>
        </AuthProvider>
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
