import {
  Box,
  Button,
  Checkbox,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Tag,
  Input,
  SimpleGrid,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { useMutation } from 'react-query'
import { Modal } from '../../components/Modal'
import { api } from '../../services/api'
import { useRecipes } from '../../services/hooks/useRecipes'
import { SearchContext } from '../../context/SearchContext'
import { add, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Recipes } from '../../Interfaces/recipes'
import CurrencyFormat from 'react-currency-format'
import RecipesCreateOrUpdate from './form'
import InputMask from 'react-input-mask'

export function Revenues() {
  const toast = useToast()

  const [currentRecipe, setCurrentRecipe] = useState<Recipes>()

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const [periodStartDate, setPeriodStartDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'dd/MM/yyyy'),
  )

  const [periodEndDate, setPeriodEndDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'dd/MM/yyyy'),
  )

  const { data, isLoading, isFetching, error, refetch } = useRecipes(
    periodStartDate.toString(),
    periodEndDate.toString(),
  )

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    refetch()
  }, [queryContextParams])

  useEffect(() => {
    return () => {
      clearQuery()

      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentRecipe) removeUser.mutateAsync(currentRecipe)

    onClose()
  }

  const removeUser = useMutation(
    async (recipes: Recipes) => {
      const response = await api.delete(`recipes/${recipes.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
      }

      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleModalOpen = (recipes = null) => {
    setCurrentRecipe({} as Recipes)
    if (!recipes) {
      onCreateOpen()
    } else {
      setCurrentRecipe(recipes)
      recipes.deleted ? onOpen() : onCreateOpen()
    }
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Receitas -{' '}
          <Tag fontSize="30">
            {format(new Date(), 'MMMM', {
              locale: ptBR,
            }).toLocaleUpperCase()}
          </Tag>
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>

        <Heading size="md" fontWeight="normal">
          Período inicial:{' '}
          <Input
            label="Período Inicial"
            as={InputMask}
            defaultValue={periodStartDate}
            onChange={(e) => setPeriodStartDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>
        <Heading size="md" fontWeight="normal" marginLeft="1rem" marginRight="1rem">
          Período final:{' '}
          <Input
            label="Período final"
            as={InputMask}
            defaultValue={periodEndDate}
            onChange={(e) => setPeriodEndDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>

        <Button
          onClick={() => handleModalOpen()}
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}
        >
          Criar Novo
        </Button>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter as receitas</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>OS</Th>
              <Th>Nome da receita</Th>
              <Th>Tipo</Th>
              <Th>Categoria</Th>
              <Th>Status receita</Th>
              <Th>Valor</Th>
              <Th width="4" />
              <Th width="4" />
            </Tr>
          </Thead>
          <Tbody>
            {data?.recipes.map((recipes: Recipes) => {
              return (
                <Tr key={recipes.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Text fontWeight="bold">{recipes.id_os}</Text>
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{recipes.description}</Text>
                      <Text fontSize="small" color="gray.300">
                        {recipes.receipt_date &&
                          format(
                            add(new Date(recipes.receipt_date), {
                              hours: 4,
                            }),
                            'dd/MM/yyyy',
                          )}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    {(() => {
                      switch (recipes.type) {
                        case 'VARIAVEL':
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              VARIÁVEL
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              FIXO
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    {(() => {
                      switch (recipes.category) {
                        case 'OUTROS':
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              OUTROS
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              EMPRESA
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    {(() => {
                      switch (recipes.receipt_status) {
                        case 'PENDENTE':
                          return (
                            <Tag size="md" variant="solid" colorScheme="red">
                              PENDENTE
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              PAGO
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    R${' '}
                    <CurrencyFormat
                      displayType="text"
                      decimalSeparator="."
                      thousandSeparator=","
                      value={recipes.value}
                    />
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen(recipes)}
                      size="sm"
                      fontSize="sm"
                      colorScheme="purple"
                      leftIcon={<Icon as={RiPencilLine} />}
                    >
                      Editar
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen({ ...recipes, deleted: true })}
                      size="sm"
                      disabled={recipes.id_os !== null && recipes.id_os !== undefined}
                      fontSize="sm"
                      colorScheme="red"
                      leftIcon={<Icon as={RiDeleteBin6Line} />}
                    >
                      Remover
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Modal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        title={currentRecipe?.id ? 'Editar Receita' : 'Nova Receita'}
        buttonTitle="Adicionar"
        size="xl"
        buttonAction={handleRemoveButton}
        hasFooter={false}
      >
        <RecipesCreateOrUpdate recipes={currentRecipe} onClose={onCreateClose} />
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Remover Receita"
        buttonTitle="Remover"
        buttonAction={handleRemoveButton}
      >
        <List spacing={3}>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Nome: ${currentRecipe?.description}`}
          </ListItem>
        </List>
      </Modal>
    </Box>
  )
}
