import { Box, Button, Flex, Heading, Tag } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface Props {
  orderNumber?: number
  tagTitle?: string
  demandId: number
}

export function Header({ orderNumber = 2, tagTitle, demandId }: Props) {
  return (
    <Flex justifyContent="space-between" align="center">
      <Box>
        <Heading size="lg" fontWeight="normal">
          <Tag size="lg" variant="solid" colorScheme="teal">
            {orderNumber ? `Ordem ${orderNumber}` : tagTitle}
          </Tag>
        </Heading>
      </Box>
      <Button
        size="sm"
        fontSize="sm"
        colorScheme="blue"
        as={Link}
        to={`/demands/${demandId}/services_order/${orderNumber}`}
      >
        Detalhar
      </Button>
    </Flex>
  )
}
