import { useQuery } from 'react-query'
import { RawMaterial } from '../../Interfaces/rawMaterial'
import { api } from '../api'

type GetRawMaterialsResponse = {
  totalCount: number
  raw_materials: RawMaterial[]
}

type QueryRawMaterialType = {
  page?: number
  category?: string
  name?: string
}

export async function getRawMaterials(params: QueryRawMaterialType): Promise<GetRawMaterialsResponse> {
  const { data } = await api.get('raw-materials', {
    params,
  })

  return {
    raw_materials: data.data,
    totalCount: data.meta.total,
  }
}

export function useRawMaterials(params: QueryRawMaterialType) {
  return useQuery(['raw_material', params], () => getRawMaterials(params), {
    staleTime: 5000,
  })
}
