import { Table, Tbody, Td, Tr, Th, Thead, Tooltip, IconButton, Icon } from '@chakra-ui/react'
import { format } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import { RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { Step } from '../../Enuns/step.enum'
import { WorkSchedule } from '../../Interfaces/workSchedule'

interface Props {
  workSchedules: WorkSchedule[]
  handleAction: (step: Step, workSchedule: any) => void
  step: Step
}

export function List({ workSchedules, handleAction, step }: Props) {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Data</Th>
            <Th>Início</Th>
            <Th>Final</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {workSchedules?.map((workSchedule) => {
            return (
              <Tr key={workSchedule.id}>
                <Td>{format(parseISO(workSchedule.work_date), 'dd/MM/yyyy')}</Td>
                <Td>{workSchedule.start_work}</Td>
                <Td>{workSchedule.end_work}</Td>
                <Td display="grid" gridGap="2" gridTemplateColumns="1fr 1fr 1fr">
                  <Tooltip label="Editar" placement="top">
                    <IconButton
                      onClick={() => handleAction(step, workSchedule)}
                      size="md"
                      fontSize="sm"
                      colorScheme="purple"
                      aria-label="Editar"
                      icon={<Icon as={RiPencilLine} />}
                    />
                  </Tooltip>
                  <Tooltip label="Remover" placement="top">
                    <IconButton
                      onClick={() => handleAction(step, { ...workSchedule, deleted: true })}
                      size="md"
                      fontSize="sm"
                      colorScheme="red"
                      aria-label="Remover"
                      icon={<Icon as={RiDeleteBin6Line} />}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}
