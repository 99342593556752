import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Divider, Flex, Heading, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/layout'
import { Button, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { differenceInHours, format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { RiCheckLine } from 'react-icons/ri'
import InputMask from 'react-input-mask'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { CoastRawMaterial } from '../../../components/CoastRawMaterial'
import { Input } from '../../../components/Form/Input'
import { Select } from '../../../components/Form/Select'
import { Modal } from '../../../components/Modal'
import { ServiceOrderGradeList } from '../../../components/ServiceOrderGradeList'
import { cookies } from '../../../context/AuthContext'
import { ConfigurationContext } from '../../../context/ConfigurationContext'
import { Step } from '../../../Enuns/step.enum'
import { Demand } from '../../../Interfaces/demands'
import { Notifications } from '../../../Interfaces/notifications'
import { Product } from '../../../Interfaces/products'
import { RawMaterial } from '../../../Interfaces/rawMaterial'
import { ServiceOrders } from '../../../Interfaces/serviceOrders'
import { ServiceRawMaterials } from '../../../Interfaces/serviceRawMaterials'
import { WorkSchedule } from '../../../Interfaces/workSchedule'
import { api } from '../../../services/api'
import { pdfRows } from '../../../utils/pdfRows'
import { categoryOptions } from '../../../utils/status'
import ProdcutsCreateOrUpdate from '../prodcuts/form'
import RawMaterialServiceCreateOrUpdate from './rawMaterialService/form'
import WorkScheduleCreateOrUpdate from './workSchedule/form'

const payday = yup.object().shape({
  payday: yup.string().required('Informe a data do pagamento'),
})

export interface WorkSchedulesTypes {
  all?: WorkSchedule[]
  cleaning: WorkSchedule[]
  painting: WorkSchedule[]
  packaging: WorkSchedule[]
}

export function ServiceOrderList({ match }: any) {
  const history = useHistory()
  const { id, demandId }: { id: string; demandId: string } = match.params

  const { search } = useLocation()
  const delivered = new URLSearchParams(search).get('delivered')

  // hooks
  const toast = useToast()

  const { configuration } = useContext(ConfigurationContext)

  // Disclosure
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()
  const { isOpen: isCreateScheduleOpen, onOpen: onCreateScheduleOpen, onClose: onCreateScheduleClose } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isRemoveWorkSchedule,
    onOpen: onRemoveWorkScheduleOpen,
    onClose: onRemoveWorkScheduleClose,
  } = useDisclosure()

  const {
    isOpen: isCreateRawMaterial,
    onOpen: onCreateRawMaterialOpen,
    onClose: onCreateRawMaterialClose,
  } = useDisclosure()

  const { populateNotifications } = useContext(ConfigurationContext)

  useEffect(() => {
    ;(async () => {
      if (cookies.get('@AXAuth:Token')) {
        const { data } = await api.get<Notifications>('/raw-material-notifications')

        populateNotifications(data)
      }
    })()
  }, [isCreateRawMaterial])

  const {
    isOpen: isRemoveRawMaterial,
    onOpen: onRemoveRawMaterialOpen,
    onClose: onRemoveRawMaterialClose,
  } = useDisclosure()

  // States
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  const [cleaningRows, setCleaningRows] = useState<number>()
  const [packgingRows, setPackgingRows] = useState<number>()
  const [paitingRows, setPaitingRows] = useState<number>()
  const [productRows, setProductRows] = useState<number>()

  const [selectedWorkSchedule, setSelectedWorkSchedule] = useState<WorkSchedule>()

  const [selectedRawMaterial, setSelectedRawMaterial] = useState<ServiceRawMaterials>()

  const [serviceOrderPrice, setServiceOrderPrice] = useState(0)

  const [rawMaterialsCleaning, setRawMaterialsCleaning] = useState<RawMaterial[]>([])
  const [rawMaterialsPackaging, setRawMaterialsPackaging] = useState<RawMaterial[]>([])
  const [rawMaterialsPainting, setRawMaterialsPainting] = useState<RawMaterial[]>([])

  const [step, setStep] = useState()

  const [liquidValue, setLiquidValue] = useState<number>(0)

  const queryClient = useQueryClient()

  const { data: demands } = useQuery(
    'demand',
    async () => {
      const response = await api.get<Demand>(`demands/${demandId}`)

      return response.data
    },
    { retry: false },
  )

  const { data: serviceOrder, refetch } = useQuery(
    'serviceOrderProduct',
    async () => {
      const response = await api.get<ServiceOrders>(`/demands/${demandId}/service-orders/${id}`)

      const cleaning = []
      const painting = []
      const packaging = []

      response.data.serviceRawMaterials.forEach((x) => {
        switch (x.step) {
          case Step.EMBALAGEM:
            packaging.push(
              response.data.rawMaterials
                .filter((y) => y.id === x.raw_material_id)
                .map((k) => ({ ...k, spent_amount: x.spent_amount })),
            )
            break
          case Step.PINTURA:
            painting.push(
              response.data.rawMaterials
                .filter((y) => y.id === x.raw_material_id)
                .map((k) => ({ ...k, spent_amount: x.spent_amount })),
            )
            break
          default:
            cleaning.push(
              response.data.rawMaterials
                .filter((y) => y.id === x.raw_material_id)
                .map((k) => ({ ...k, spent_amount: x.spent_amount })),
            )
            break
        }
      })

      setRawMaterialsCleaning(cleaning.map((x) => ({ ...x })))
      setRawMaterialsPackaging(packaging)
      setRawMaterialsPainting(painting)

      return response.data
    },
    {
      retry: false,
    },
  )

  const { data: workSchedules, refetch: workRefetch } = useQuery(
    'workSchedule',
    async () => {
      const response = await api.get<WorkSchedule[]>(`/service-orders/${id}/work-schedules`)

      const cleaning = []
      const painting = []
      const packaging = []

      response.data.forEach((x) => {
        switch (x.step) {
          case Step.EMBALAGEM:
            packaging.push(x)
            break
          case Step.PINTURA:
            painting.push(x)
            break
          default:
            cleaning.push(x)
            break
        }
      })

      return {
        all: response.data,
        cleaning,
        painting,
        packaging,
      } as WorkSchedulesTypes
    },
    {
      retry: false,
    },
  )

  const handleAction = (product = null) => {
    refetch()
    setSelectedProduct({} as Product)

    if (!product) {
      onCreateOpen()
    } else {
      delete product.formatedDeliveryDate

      setSelectedProduct(product)
      product.deleted ? onOpen() : onCreateOpen()
    }
  }

  const handleRemoveButton = () => {
    if (selectedProduct) removeDemand.mutateAsync(selectedProduct)

    onClose()
  }

  const removeDemand = useMutation(
    async (product: Product) => {
      const response = await api.delete(`products/${product.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
        queryClient.invalidateQueries('serviceOrderProduct')
      }
      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao remover pedido', status: 'error' })
        }
      },
    },
  )

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: 'onSubmit',
  })

  useEffect(() => {
    setValue('status', serviceOrder?.status)
    setValue('num_resp_cleaning', serviceOrder?.num_resp_cleaning)
    setValue('num_resp_painting', serviceOrder?.num_resp_painting)
    setValue('num_resp_packaging', serviceOrder?.num_resp_packaging)

    const date = serviceOrder?.payday?.split('-')

    if (date) {
      const payday = format(new Date(`${date[0]}/${date[1]}/${date[2]}`), 'dd/MM/yyyy')

      setValue2('payday', payday)
    } else {
      setValue2('payday', '')
    }
  }, [serviceOrder])

  useEffect(() => {
    calculateLiquid()
  }, [serviceOrder, workSchedules?.all, configuration])

  const calculateLiquid = () => {
    let totalWork = 0
    let totalBurn = 0
    let orderPrice = 0
    let totalMaterial = 0

    if (workSchedules && workSchedules.all) {
      const totalHours = workSchedules.all.reduce(
        (prev, curr) => prev + Math.abs(differenceInHours(timeToDate(curr.end_work), timeToDate(curr.start_work))),
        0,
      )

      totalWork = totalHours * Math.abs(configuration?.work_hour_value)
    }

    if (serviceOrder) {
      totalBurn =
        serviceOrder.products.reduce((prev, curr) => prev + curr.oven_space, 0) *
        Math.abs(configuration?.oven_burn_value)

      orderPrice = serviceOrder.products.reduce((prev, curr) => prev + curr.amount * curr.price, 0)

      setServiceOrderPrice(orderPrice)

      const amountAndPrice = serviceOrder.serviceRawMaterials.map((materialOrder) => ({
        amount: materialOrder.spent_amount,
        price: serviceOrder.rawMaterials.find((x) => x.id === materialOrder.raw_material_id).price,
      }))

      totalMaterial = amountAndPrice.reduce((prev, curr) => prev + curr.amount * curr.price, 0)
    }

    setLiquidValue(orderPrice - totalWork - totalBurn - totalMaterial)
  }

  const changeSatatus = useMutation(
    async (order: any) => {
      const response = await api.put(`/demands/${demands.id}/service-orders/${serviceOrder.id}`, {
        ...order,
        num_resp_cleaning: Number(order.num_resp_cleaning),
        num_resp_painting: Number(order.num_resp_painting),
        num_resp_packaging: Number(order.num_resp_packaging),
      })

      setValue('status', response.data?.status)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('serviceOrderProduct')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<any> = async (values) => {
    calculateLiquid()
    delete values.product_rows
    delete values.cleaning_rows
    delete values.painting_rows
    delete values.packaging_rows
    changeSatatus.mutateAsync(values)
  }

  const handleWorkSchedule = (step, workSchedule = null) => {
    setStep(step)
    setSelectedWorkSchedule({} as WorkSchedule)

    if (!workSchedule) {
      onCreateScheduleOpen()
    } else {
      setSelectedWorkSchedule(workSchedule)
      workSchedule.deleted ? onRemoveWorkScheduleOpen() : onCreateScheduleOpen()
    }
  }

  const handleRawMaterial = (step, rawMaterial = null) => {
    setStep(step)

    setSelectedRawMaterial({} as ServiceRawMaterials)

    if (!rawMaterial) {
      onCreateRawMaterialOpen()
    } else {
      setSelectedRawMaterial(rawMaterial[0])
      rawMaterial.deleted ? onRemoveRawMaterialOpen() : onCreateRawMaterialOpen()
    }
  }

  const handleWorkScheduleremove = () => {
    if (selectedWorkSchedule) removeWorkSchedule.mutateAsync(selectedWorkSchedule)

    onRemoveWorkScheduleClose()
  }

  const removeWorkSchedule = useMutation(
    async (work: any) => {
      const response = await api.delete(`/service-orders/${serviceOrder.id}/work-schedules/${work.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        workRefetch()
      }

      onRemoveWorkScheduleClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao remover pedido', status: 'error' })
        }
      },
    },
  )

  const handleRawMaterialRemove = () => {
    if (selectedRawMaterial) removeRaw.mutateAsync(selectedRawMaterial)

    onRemoveRawMaterialClose()
  }

  const removeRaw = useMutation(
    async (work: any) => {
      const response = await api.delete(`/service-orders/${serviceOrder.id}/raw-materials/${work.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso' })
        refetch()
        // queryClient.invalidateQueries('demands')
        // queryClient.invalidateQueries('serviceOrderProduct')
      }

      onRemoveWorkScheduleClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao remover pedido', status: 'error' })
        }
      },
    },
  )

  const handlePrintOrder = async () => {
    const response = await api.get(`/demands/${demandId}/service-orders/${id}/generate-pdf`, {
      responseType: 'blob',
      params: {
        packaging_rows: packgingRows,
        cleaning_rows: cleaningRows,
        painting_rows: paitingRows,
        product_rows: productRows,
      },
    })

    const file = new Blob([response.data], { type: 'application/pdf' })

    window.open(URL.createObjectURL(file), '_blank')
  }

  const handlePrintEtiqueta = async () => {
    try {
      const response = await api.post(`/demands/${demandId}/service-orders/${id}/generate-etiqueta`)

      if (response.status === 200) {
        toast({
          description: response.data.message,
          status: 'success',
        })
      }
    } catch (error) {
      toast({
        description: 'Erro ao gerar a etiqueta',
        status: 'error',
      })
    }
  }

  const timeToDate = (time) => {
    const t = time.split(':')

    const d = new Date()

    d.setHours(t[0], t[1], t[2])

    return d
  }

  const updatePayday = useMutation(
    async ({ payday }: any) => {
      const date = payday.split('/')

      const response = await api.put(`/demands/${demands.id}/service-orders/${serviceOrder.id}`, {
        num_resp_cleaning: Number(serviceOrder.num_resp_cleaning),
        num_resp_painting: Number(serviceOrder.num_resp_painting),
        num_resp_packaging: Number(serviceOrder.num_resp_packaging),
        payday: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        status: serviceOrder.status,
      })
      if (response) {
        toast({ description: 'Ordem de serviço paga com sucesso' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(payday),
  })

  const errors = formState.errors

  const handleCreate: SubmitHandler<any> = async (values) => {
    await updatePayday.mutateAsync(values)
  }

  return (
    <>
      <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form">
        <Flex mb="8" justifyContent="space-between" align="flex-end">
          <Box>
            <Heading size="lg" fontWeight="normal" mb="2">
              Ordem de serviço Nº {id}
            </Heading>
            <VStack spacing="8" as="form" display={delivered ? 'none' : 'flex'}>
              <Select
                label="Andamento"
                {...register('status')}
                options={categoryOptions}
                onChange={(e) => {
                  setValue('status', e.target.value)
                  handleSubmit(handleCreateOrUpdate)()
                }}
                placeholder="Selecione"
              />
            </VStack>
            <Text fontSize="1xl" mt="10">
              <Text fontSize="2xl" bg="gray.400" p={1} borderRadius={10}>
                {demands?.client?.company_name}
              </Text>
            </Text>
          </Box>
          <Flex gridGap="1rem">
            <Text fontSize="2xl" mb="2" bg="pink.400" py="2" px="10" borderRadius={10}>
              Bruto:
              {
                <CurrencyFormat
                  displayType="text"
                  decimalSeparator="."
                  thousandSeparator=","
                  prefix="R$ "
                  value={serviceOrderPrice.toFixed(2)}
                />
              }
            </Text>

            <Text fontSize="2xl" mb="2" bg="green.400" py="2" px="10" borderRadius={10}>
              <Stack direction={['column', 'row']} spacing="5px">
                <span>Líquido: R$ </span>
                {
                  <CurrencyFormat
                    displayType="text"
                    decimalSeparator="."
                    thousandSeparator=","
                    value={liquidValue.toFixed(2)}
                  />
                }
              </Stack>
            </Text>
          </Flex>
        </Flex>

        <Divider my="6" borderColor="gray.700" />

        <Box display={delivered ? 'none' : ''}>
          <VStack spacing="8" as="form" mb="10">
            <Select
              label="Quantidade de linhas"
              {...register('product_rows')}
              options={pdfRows}
              onChange={(e) => {
                setProductRows(Number(e.target.value))
              }}
              placeholder="Selecione"
            />
          </VStack>

          <ServiceOrderGradeList title="Produtos" products={serviceOrder?.products} handleAction={handleAction} />

          <Text fontSize="2xl">Matéria Prima</Text>

          <Divider my="6" borderColor="gray.700" />
          <VStack spacing="8" as="form" mb="2">
            <Input
              label="Custo de LIMPEZA"
              type="number"
              {...register('num_resp_cleaning')}
              onBlur={(e) => {
                setValue('num_resp_cleaning', e.target.value)
                handleSubmit(handleCreateOrUpdate)()
              }}
            />
          </VStack>
          <VStack spacing="8" as="form" mb="10">
            <Select
              label="Quantidade de linhas"
              {...register('cleaning_rows')}
              options={pdfRows}
              onChange={(e) => {
                setCleaningRows(Number(e.target.value))
              }}
              placeholder="Selecione"
            />
          </VStack>
          <CoastRawMaterial
            title="Limpeza"
            workSchedules={workSchedules?.cleaning}
            rawMaterials={rawMaterialsCleaning}
            handleWorkSchedule={handleWorkSchedule}
            handleRawMaterial={handleRawMaterial}
            step={Step.LIMPEZA}
          />
          <VStack spacing="8" as="form" mb="2">
            <Input
              label="Custo de PINTURA"
              {...register('num_resp_painting')}
              onBlur={(e) => {
                setValue('num_resp_painting', e.target.value)
                handleSubmit(handleCreateOrUpdate)()
              }}
            />
          </VStack>
          <VStack spacing="8" as="form" mb="10">
            <Select
              label="Quantidade de linhas"
              {...register('painting_rows')}
              options={pdfRows}
              onChange={(e) => {
                setPaitingRows(Number(e.target.value))
              }}
              placeholder="Selecione"
            />
          </VStack>
          <CoastRawMaterial
            title="Pintura"
            workSchedules={workSchedules?.painting}
            rawMaterials={rawMaterialsPainting}
            handleWorkSchedule={handleWorkSchedule}
            handleRawMaterial={handleRawMaterial}
            step={Step.PINTURA}
          />

          <VStack spacing="8" as="form" mb="2">
            <Input
              label="Custo de EMBALAGENS"
              {...register('num_resp_packaging')}
              onBlur={(e) => {
                setValue('num_resp_packaging', e.target.value)
                handleSubmit(handleCreateOrUpdate)()
              }}
            />
          </VStack>
          <VStack spacing="8" as="form" mb="10">
            <Select
              label="Quantidade de linhas"
              {...register('packaging_rows')}
              options={pdfRows}
              onChange={(e) => {
                setPackgingRows(Number(e.target.value))
              }}
              placeholder="Selecione"
            />
          </VStack>
          <CoastRawMaterial
            title="Embalagem"
            workSchedules={workSchedules?.packaging}
            rawMaterials={rawMaterialsPackaging}
            handleWorkSchedule={handleWorkSchedule}
            handleRawMaterial={handleRawMaterial}
            step={Step.EMBALAGEM}
          />

          <Modal
            isOpen={isCreateOpen}
            onClose={onCreateClose}
            title="Novo Produto"
            buttonTitle="Adicionar"
            size="xl"
            hasFooter={false}
            buttonAction={() => {
              console.log(1)
            }}
          >
            <ProdcutsCreateOrUpdate selectedProduct={selectedProduct} orderId={id} onClose={onCreateClose} />
          </Modal>

          <Modal
            isOpen={isCreateScheduleOpen}
            onClose={onCreateScheduleClose}
            title="Novo Custo"
            buttonTitle="Adicionar"
            size="xl"
            hasFooter={false}
            buttonAction={() => {
              console.log(1)
            }}
          >
            <WorkScheduleCreateOrUpdate
              step={step}
              selectedProduct={selectedWorkSchedule}
              orderId={id}
              onClose={onCreateScheduleClose}
            />
          </Modal>

          <Modal
            isOpen={isCreateRawMaterial}
            onClose={onCreateRawMaterialClose}
            title="Novo Custo - Matéria Prima"
            buttonTitle="Adicionar"
            size="xl"
            hasFooter={false}
            buttonAction={() => {
              console.log(1)
            }}
          >
            <RawMaterialServiceCreateOrUpdate
              step={step}
              selectedRawMaterial={selectedRawMaterial}
              orderId={id}
              onClose={onCreateRawMaterialClose}
            />
          </Modal>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Remover Produto"
            buttonTitle="Remover"
            buttonAction={handleRemoveButton}
          >
            <List spacing={3}>
              <ListItem>
                <ListIcon as={RiCheckLine} color="green.500" />
                Produto: {selectedProduct?.description}
              </ListItem>
            </List>
          </Modal>

          <Modal
            isOpen={isRemoveWorkSchedule}
            onClose={onRemoveWorkScheduleClose}
            title="Remover Custo"
            buttonTitle="Remover"
            buttonAction={handleWorkScheduleremove}
          >
            <List spacing={3}>
              <ListItem>
                <ListIcon as={RiCheckLine} color="green.500" />
                Hora Início: {selectedWorkSchedule?.start_work}
              </ListItem>
              <ListItem>
                <ListIcon as={RiCheckLine} color="green.500" />
                Hora Fim: {selectedWorkSchedule?.end_work}
              </ListItem>
            </List>
          </Modal>

          <Modal
            isOpen={isRemoveRawMaterial}
            onClose={onRemoveRawMaterialClose}
            title="Remover Matéria Gasta"
            buttonTitle="Remover"
            buttonAction={handleRawMaterialRemove}
          >
            <List spacing={3}>
              <ListItem>
                <ListIcon as={RiCheckLine} color="green.500" />
                Quantidade: {selectedRawMaterial?.spent_amount}
              </ListItem>
            </List>
          </Modal>

          <Flex gap="4" mt="4">
            <Button bg="pink.500" onClick={handlePrintOrder}>
              Imprimir Ordem
            </Button>
            <Button bg="pink.500" onClick={handlePrintEtiqueta}>
              Imprimir Etiqueta
            </Button>
          </Flex>
        </Box>
      </Box>
      <VStack spacing="8" as="form" mb="10" onSubmit={handleSubmit2(handleCreate)}>
        <Input
          label="Data do Pagamento"
          {...register2('payday')}
          as={InputMask}
          mask="99/99/9999"
          error={errors.delivery_date as FieldError}
        />
        <Button type="submit" colorScheme="pink">
          Pagar
        </Button>
      </VStack>
    </>
  )
}
