import { Box, Button, Flex, Heading, Tag } from '@chakra-ui/react'
import { Step } from '../../Enuns/step.enum'

interface Props {
  title: string
  handleAction: (step: any, workSchedule?: any) => void
  step: Step
}

export function Header({ title, handleAction, step }: Props) {
  return (
    <Flex justifyContent="space-between" align="center">
      <Box>
        <Heading size="lg" fontWeight="normal">
          <Tag size="lg" variant="solid" colorScheme="teal">
            {title}
          </Tag>
        </Heading>
      </Box>

      <Button
        size="sm"
        fontSize="sm"
        colorScheme="blue"
        onClick={() => {
          handleAction(step)
        }}
      >
        Novo
      </Button>
    </Flex>
  )
}
