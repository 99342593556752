import { useQuery } from 'react-query'
import { Provider } from '../../Interfaces/provider'
import { api } from '../api'

type GetProvidersResponse<T> = {
  totalCount?: number
  providers: T
}

type QueryRawMaterialType = {
  page?: number
  cnpj?: string
  name?: string
}

export async function getProviders<T>(
  isSelect = false,
  params?: QueryRawMaterialType,
): Promise<GetProvidersResponse<T>> {
  let { data } = await api.get('providers', {
    params: params,
  })

  if (isSelect) {
    data = data.map((x: Provider) => ({
      label: x.name,
      value: x.id,
    }))

    return {
      providers: data,
    }
  }

  return {
    providers: data.data,
    totalCount: data.meta.total,
  }
}

export function useProviders<T>(isSelect = false, params?: QueryRawMaterialType) {
  return useQuery<GetProvidersResponse<T>>(['providers', params], () => getProviders<T>(isSelect, params), {
    staleTime: 5000,
  })
}
